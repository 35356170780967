import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { Loader, SearchBar } from "../../../components";
import {
  Box,
  Typography,
  Button,
  Divider,
  Snackbar,
  Alert,
  Paper,
  Dialog,
  DialogTitle,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  TextField,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { makeStyles } from "@mui/styles";
import { Col, Row, Form } from "react-bootstrap";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

const UbahJualBaru = () => {
  const { user } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);
  const [validated, setValidated] = useState(false);
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const [open, setOpen] = useState(false);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  // Data Register/Pembeli
  const [registers, setRegisters] = useState([]);
  const [noRegister, setNoRegister] = useState("");
  const [namaRegister, setNamaRegister] = useState("");
  const [almRegister, setAlmRegister] = useState("");
  const [almKantor, setAlmKantor] = useState("");
  const [tlpRegister, setTlpRegister] = useState("");
  const [noKtpRegister, setNoKtpRegister] = useState("");
  const [noKKRegister, setNoKKRegister] = useState("");
  const [namaPjmRegister, setNamaPjmRegister] = useState("");
  const [noKtpPjmRegister, setNoKtpPjmRegister] = useState("");
  const [kodePekerjaanPenjamin, setKodePekerjaanPenjamin] = useState("");
  const [kodePekerjaanPenjaminLama, setKodePekerjaanPenjaminLama] =
    useState("");
  const [ketPekerjaanPenjamin, setKetPekerjaanPenjamin] = useState("");
  const [namaRefRegister, setNamaRefRegister] = useState("");
  const [almRefRegister, setAlmRefRegister] = useState("");
  const [tlpRefRegister, setTlpRefRegister] = useState("");
  const [kodeMarketing, setKodeMarketing] = useState("");
  const [kodeMarketingLama, setKodeMarketingLama] = useState("");
  const [kodeSurveyor, setKodeSurveyor] = useState("");
  const [kodeSurveyorLama, setKodeSurveyorLama] = useState("");
  const [kodePekerjaan, setKodePekerjaan] = useState("");
  const [kodePekerjaanLama, setKodePekerjaanLama] = useState("");
  const [ketPekerjaan, setKetPekerjaan] = useState("");
  const [kodeKelurahan, setKodeKelurahan] = useState("");
  const [kodeKelurahanLama, setKodeKelurahanLama] = useState("");
  const [kodeLeasing, setKodeLeasing] = useState("");
  const [kodeCOA, setKodeCOA] = useState("");
  const [COAsData, setCOAsData] = useState([]);
  const [kodeLeasingLama, setKodeLeasingLama] = useState("");
  const [marketings, setMarketings] = useState([]);
  const [surveyors, setSurveyors] = useState([]);
  const [pekerjaans, setPekerjaans] = useState([]);
  const [kelurahans, setKelurahans] = useState([]);
  const [leasings, setLeasings] = useState([]);

  // Data Motor -> Dari Stok
  const [noRangka, setNoRangka] = useState("");
  const [tempNoRangka, setTempNoRangka] = useState("");
  const [noMesin, setNoMesin] = useState("");
  const [nopol, setNopol] = useState("");
  const [tipe, setTipe] = useState("");
  const [namaWarna, setNamaWarna] = useState("");
  const [tahun, setTahun] = useState("");
  const [stoks, setStoks] = useState([]);

  // Data Penjualan -> dari input
  const [hargaTunai, setHargaTunai] = useState("");
  const [uangMuka, setUangMuka] = useState("");
  const [sisaPiutang, setSisaPiutang] = useState("");
  const [angPerBulan, setAngPerBulan] = useState("");
  const [tenor, setTenor] = useState("");
  const [tenorInput, setTenorInput] = useState("");
  const [bunga, setBunga] = useState("");
  const [jumlahPiutang, setJumlahPiutang] = useState("");
  const [angModal, setAngModal] = useState("");
  const [angBunga, setAngBunga] = useState("");
  const [noJual, setNoJual] = useState("");
  const [noKwitansi, setNoKwitansi] = useState(user.kodeKwitansi);
  const [tglJual, setTglJual] = useState("");
  const [tglJualDate, setTglJualDate] = useState("");
  const [jenisJual, setJenisJual] = useState("");
  const [tglAng, setTglAng] = useState("");
  const [inputTglAng, setInputTglAng] = useState(new Date());
  const [tglAngAkhir, setTglAngAkhir] = useState("");
  const [tglAngAkhirFormatted, setTglAngAkhirFormatted] = useState("");

  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchTermRegister, setSearchTermRegister] = useState("");
  const [openRegister, setOpenRegister] = React.useState(false);

  const [sisaBulan, setSisaBulan] = useState("");

  const [openCOA, setOpenCOA] = useState(false);
  const [searchTermCOA, setSearchTermCOA] = useState("");
  const handleClickOpenCOA = () => {
    setOpenCOA(true);
  };

  const handleCloseCOA = () => {
    setOpenCOA(false);
  };

  const classes = useStyles();

  const handleClickOpenRegister = () => {
    setOpenRegister(true);
  };

  const handleCloseRegister = () => {
    setOpenRegister(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const tempPostsCOA = COAsData.filter((val) => {
    if (searchTermCOA === "") {
      return val;
    } else if (
      val.kodeCOA.toUpperCase().includes(searchTermCOA.toUpperCase()) ||
      val.namaCOA.toUpperCase().includes(searchTermCOA.toUpperCase())
    ) {
      return val;
    }
  });

  const jenisJualOption = [{ label: "TUNAI" }, { label: "KREDIT" }];

  const marketingOptions = marketings.map((marketing) => ({
    value: marketing.kodeMarketing,
    label: `${marketing.kodeMarketing} - ${marketing.namaMarketing}`,
  }));

  const surveyorOptions = surveyors.map((surveyor) => ({
    value: surveyor.kodeSurveyor,
    label: `${surveyor.kodeSurveyor} - ${surveyor.namaSurveyor}`,
  }));

  const pekerjaanOptions = pekerjaans.map((pekerjaan) => ({
    value: pekerjaan.kodePekerjaan,
    label: `${pekerjaan.kodePekerjaan} - ${pekerjaan.namaPekerjaan}`,
  }));

  const kelurahanOptions = kelurahans.map((kelurahan) => ({
    value: kelurahan.kodeKelurahan,
    label: `${kelurahan.kodeKelurahan} - ${kelurahan.namaKelurahan}`,
  }));

  const leasingOptions = leasings.map((leasing) => ({
    value: leasing.kodeLeasing,
    label: `${leasing.kodeLeasing} - ${leasing.namaLeasing}`,
  }));

  const norangOptions = stoks.map((stok) => ({
    label: `${stok.noRangka}`,
  }));

  const tempPostsRegister = registers.filter((val) => {
    if (searchTermRegister === "") {
      return val;
    } else if (
      val.noRegister.toUpperCase().includes(searchTermRegister.toUpperCase()) ||
      val.tanggalRegister
        .toUpperCase()
        .includes(searchTermRegister.toUpperCase()) ||
      val.namaRegister.toUpperCase().includes(searchTermRegister.toUpperCase())
    ) {
      return val;
    }
  });

  function addMonths(date, months) {
    let tempDate = new Date(date);
    let d = tempDate.getDate();
    tempDate.setMonth(tempDate.getMonth() + +months - 1);
    if (tempDate.getDate() != d) {
      tempDate.setDate(0);
    }
    return tempDate;
  }

  const countDateDuration = (e) => {
    var finalDate = addMonths(inputTglAng, parseInt(e.target.value));
    var calculatedDateResult =
      finalDate.getDate().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      (finalDate.getMonth() + 1).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      finalDate.getFullYear();
    if (jenisJual === "KREDIT") {
      setTglAngAkhir(finalDate);
      setTglAngAkhirFormatted(calculatedDateResult);
    }
  };

  useEffect(() => {
    getJualById();
    getStok();
    getRegister();
    getCOAsData();
    if (user.tipeUser === "OWN/DIREKSI") {
      getMarketingAllCabang();
      getSurveyorAll();
    } else {
      getMarketing();
      getSurveyor();
    }
    getPekerjaan();
    getKelurahan();
    getLeasing();
  }, []);

  const getCOAsData = async () => {
    setLoading(true);
    const allCOAs = await axios.post(`${tempUrl}/COAsKasBank`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setCOAsData(allCOAs.data);
    setLoading(false);
  };

  const getStoksByNorang = async (noRangka) => {
    const allDaftarStokByNorang = await axios.post(
      `${tempUrl}/daftarStoksByNorang`,
      {
        noRangka,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );
    if (allDaftarStokByNorang.data) {
      setNoMesin(allDaftarStokByNorang.data.noMesin);
      setTipe(allDaftarStokByNorang.data.tipe);
      setNamaWarna(allDaftarStokByNorang.data.namaWarna);
      setTahun(allDaftarStokByNorang.data.tahun);
    }
    setNoRangka(noRangka);
  };

  const getStok = async () => {
    setLoading(true);
    const allDaftarStokHasNorang = await axios.post(
      `${tempUrl}/daftarStoksNorang`,
      {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );
    setStoks(allDaftarStokHasNorang.data);
    setLoading(false);
  };

  const getRegister = async () => {
    setLoading(true);
    const allRegisters = await axios.post(`${tempUrl}/registers`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setRegisters(allRegisters.data);
    setLoading(false);
  };

  const getMarketing = async () => {
    setLoading(true);
    const allMarketings = await axios.post(`${tempUrl}/marketings`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setMarketings(allMarketings.data);
    setLoading(false);
  };

  const getMarketingAllCabang = async () => {
    setLoading(true);
    const allMarketings = await axios.post(`${tempUrl}/marketingsAllCabang`, {
      id: user._id,
      token: user.token,
    });
    setMarketings(allMarketings.data);
    setLoading(false);
  };

  const getSurveyor = async () => {
    setLoading(true);
    const allSurveyors = await axios.post(`${tempUrl}/surveyors`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setSurveyors(allSurveyors.data);
    setLoading(false);
  };

  const getSurveyorAll = async () => {
    setLoading(true);
    const allSurveyors = await axios.post(`${tempUrl}/surveyorsAllCabang`, {
      id: user._id,
      token: user.token,
    });
    setSurveyors(allSurveyors.data);
    setLoading(false);
  };

  const getPekerjaan = async () => {
    setLoading(true);
    const allPekerjaans = await axios.post(`${tempUrl}/pekerjaans`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setPekerjaans(allPekerjaans.data);
    setLoading(false);
  };

  const getKelurahan = async () => {
    setLoading(true);
    const allKelurahans = await axios.post(`${tempUrl}/kelurahans`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setKelurahans(allKelurahans.data);
    setLoading(false);
  };

  const getLeasing = async () => {
    setLoading(true);
    const allLeasing = await axios.post(`${tempUrl}/leasings`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setLeasings(allLeasing.data);
    setLoading(false);
  };

  const getJualById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/juals/${id}`, {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      // Data Register/Pembeli
      setNoRegister(response.data.noRegister);
      setNamaRegister(response.data.namaRegister);
      setAlmRegister(response.data.almRegister);
      setAlmKantor(response.data.almKantor);
      setTlpRegister(response.data.tlpRegister);
      setNoKtpRegister(response.data.noKtpRegister);
      setNoKKRegister(response.data.noKKRegister);
      setNamaPjmRegister(response.data.namaPjmRegister);
      setNoKtpPjmRegister(response.data.noKtpPjmRegister);
      setKodePekerjaanPenjamin(
        `${response.data.kodePekerjaanPenjamin.kodePekerjaan} - ${response.data.kodePekerjaanPenjamin.namaPekerjaan}`
      );
      setKodePekerjaanPenjaminLama(
        `${response.data.kodePekerjaanPenjamin.kodePekerjaan} - ${response.data.kodePekerjaanPenjamin.namaPekerjaan}`
      );
      setKetPekerjaanPenjamin(response.data.ketPekerjaanPenjamin);
      setNamaRefRegister(response.data.namaRefRegister);
      setAlmRefRegister(response.data.almRefRegister);
      setTlpRefRegister(response.data.tlpRefRegister);
      setKodeMarketing(
        `${response.data.kodeMarketing.kodeMarketing} - ${response.data.kodeMarketing.namaMarketing}`
      );
      setKodeMarketingLama(
        `${response.data.kodeMarketing.kodeMarketing} - ${response.data.kodeMarketing.namaMarketing}`
      );
      setKodeSurveyor(
        `${response.data.kodeSurveyor.kodeSurveyor} - ${response.data.kodeSurveyor.namaSurveyor}`
      );
      setKodeSurveyorLama(
        `${response.data.kodeSurveyor.kodeSurveyor} - ${response.data.kodeSurveyor.namaSurveyor}`
      );
      setKodePekerjaan(
        `${response.data.kodePekerjaan.kodePekerjaan} - ${response.data.kodePekerjaan.namaPekerjaan}`
      );
      setKodePekerjaanLama(
        `${response.data.kodePekerjaan.kodePekerjaan} - ${response.data.kodePekerjaan.namaPekerjaan}`
      );
      setKetPekerjaan(response.data.ketPekerjaan);
      setKodeKelurahan(
        `${response.data.kodeKelurahan.kodeKelurahan} - ${response.data.kodeKelurahan.namaKelurahan}`
      );
      setKodeKelurahanLama(
        `${response.data.kodeKelurahan.kodeKelurahan} - ${response.data.kodeKelurahan.namaKelurahan}`
      );
      setKodeLeasing(
        `${response.data.kodeLeasing.kodeLeasing} - ${response.data.kodeLeasing.namaLeasing}`
      );
      setKodeLeasingLama(
        `${response.data.kodeLeasing.kodeLeasing} - ${response.data.kodeLeasing.namaLeasing}`
      );
      setKodeCOA(response.data.COA.kodeCOA);

      // Data Motor -> Dari Stok
      setNoRangka(response.data.noRangka);
      setTempNoRangka(response.data.noRangka);
      setNoMesin(response.data.noMesin);
      setNopol(response.data.nopol);
      setTipe(response.data.tipe);
      setNamaWarna(response.data.namaWarna);
      setTahun(response.data.tahun);

      // Data Penjualan -> dari input
      setHargaTunai(response.data.hargaTunai.toLocaleString("en-US"));
      setUangMuka(response.data.uangMuka.toLocaleString("en-US"));
      setSisaPiutang(response.data.sisaPiutang);
      setAngPerBulan(response.data.angPerBulan.toLocaleString("en-US"));
      setTenor(response.data.tenor);
      setTenorInput(response.data.tenor);
      setBunga(response.data.bunga);
      setJumlahPiutang(response.data.jumlahPiutang);
      setAngModal(response.data.angModal);
      setAngBunga(response.data.angBunga);
      setNoJual(response.data.noJual);
      setNoKwitansi(response.data.noKwitansi);
      setTglJual(formatDate(response.data.tanggalJual));
      setTglJualDate(response.data.tanggalJual);
      setJenisJual(response.data.jenisJual);
      setTglAng(formatDate(response.data.tglAng));
      setInputTglAng(new Date(response.data.tglAng));
      setTglAngAkhir(response.data.tglAngAkhir);
      setTglAngAkhirFormatted(formatDate(response.data.tglAngAkhir));
      setSisaBulan(response.data.sisaBulan);

      if (response.data.isPost) {
        navigate(`/jual/${id}`);
      }
    }
  };

  const updateJual = async (e) => {
    e.preventDefault();
    setLoading(true);
    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

    let tglAng;
    if (inputTglAng) {
      tglAng =
        inputTglAng?.getFullYear() +
        "-" +
        (inputTglAng?.getMonth() + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        "-" +
        inputTglAng?.getDate().toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        });
    }

    let isFailedValidation =
      noJual.length === 0 ||
      tglJual.length === 0 ||
      jenisJual.length === 0 ||
      noRegister.length === 0 ||
      kodeMarketing.length === 0 ||
      kodeSurveyor.length === 0 ||
      kodePekerjaanPenjamin.length === 0 ||
      kodePekerjaan.length === 0 ||
      kodeKelurahan.length === 0 ||
      kodeLeasing.length === 0 ||
      tenorInput.length === 0 ||
      noRangka.length === 0;
    if (isFailedValidation) {
      setError(true);
      setOpen(!open);
    } else {
      try {
        const tempMarketing = await axios.post(`${tempUrl}/marketingByKode`, {
          kodeMarketing: kodeMarketing.split(" -", 1)[0],
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        const tempSurveyor = await axios.post(`${tempUrl}/surveyorByKode`, {
          kodeSurveyor: kodeSurveyor.split(" -", 1)[0],
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        const tempPekerjaanPenjamin = await axios.post(
          `${tempUrl}/pekerjaanByKode`,
          {
            kodePekerjaan: kodePekerjaanPenjamin.split(" -", 1)[0],
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
        const tempPekerjaan = await axios.post(`${tempUrl}/pekerjaanByKode`, {
          kodePekerjaan: kodePekerjaan.split(" -", 1)[0],
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        const tempKelurahan = await axios.post(`${tempUrl}/kelurahanByKode`, {
          kodeKelurahan: kodeKelurahan.split(" -", 1)[0],
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        const tempLeasing = await axios.post(`${tempUrl}/leasingByKode`, {
          kodeLeasing: kodeLeasing.split(" -", 1)[0],
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        let tempCOA = await axios.post(`${tempUrl}/COAByKode`, {
          kodeCOA,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });
        if (jenisJual === "KREDIT" && tenor - sisaBulan === 0) {
          // Update Jual, Manage Angsuran, Manager Daftar Stok
          await axios.post(
            `${tempUrl}/updateJualManageAngsuranManageDaftarStok`,
            {
              deleteAngsuranData: {
                noJual,
                kodeCabang: user.cabang._id,
                id: user._id,
                token: user.token,
              },
              angsuranData: {
                tenor: tenorInput,
                noJual,
                tglAng,
                angModal: sisaPiutang / tenorInput,
                angBunga:
                  angPerBulan.replace(/,/g, "") - sisaPiutang / tenorInput,
                angPerBulan: angPerBulan.replace(/,/g, ""),
                tglInput: current_date,
                jamInput: current_time,
                userInput: user.username,
                kodeCabang: user.cabang._id,
                id: user._id,
                token: user.token,
              },
              jualData: {
                idJual: id,
                tenor: tenorInput,
                sisaBulan: tenorInput,
                COA: tempCOA.data._id,
                noRegister,
                namaRegister,
                almRegister,
                almKantor,
                tlpRegister,
                noKtpRegister,
                noKKRegister,
                namaPjmRegister,
                noKtpPjmRegister,
                kodePekerjaanPenjamin: tempPekerjaanPenjamin.data._id,
                ketPekerjaanPenjamin,
                namaRefRegister,
                almRefRegister,
                tlpRefRegister,
                kodeMarketing: tempMarketing.data._id,
                kodeSurveyor: tempSurveyor.data._id,
                kodePekerjaan: tempPekerjaan.data._id,
                ketPekerjaan,
                kodeKelurahan: tempKelurahan.data._id,
                kodeLeasing: tempLeasing.data._id,
                noRangka,
                noMesin,
                nopol,
                tipe,
                namaWarna,
                tahun,
                hargaTunai: hargaTunai.replace(/,/g, ""),
                uangMuka: uangMuka.replace(/,/g, ""),
                sisaPiutang,
                angModal: sisaPiutang / tenorInput,
                angBunga:
                  angPerBulan.replace(/,/g, "") - sisaPiutang / tenorInput,
                angPerBulan: angPerBulan.replace(/,/g, ""),
                jumlahPiutang,
                tglUpdate: current_date,
                jamUpdate: current_time,
                userUpdate: user.username,
                kodeCabang: user.cabang._id,
                id: user._id,
                token: user.token,
                kodeCabang: user.cabang._id,
              },
              daftarStokData: {
                jenis: "BARU",
                noRangkaLama: tempNoRangka,
                noRangka,
                noJual,
                tanggalJual: tglJualDate,
                tglUpdate: current_date,
                jamUpdate: current_time,
                userUpdate: user.username,
                kodeCabang: user.cabang._id,
                id: user._id,
                token: user.token,
              },
              kodeCabang: user.cabang._id,
              id: user._id,
              token: user.token,
            }
          );
        } else {
          // Update Jual, Manage Daftar Stok
          await axios.post(`${tempUrl}/updateJualManageDaftarStok`, {
            jualData: {
              idJual: id,
              COA: tempCOA.data._id,
              noRegister,
              namaRegister,
              almRegister,
              almKantor,
              tlpRegister,
              noKtpRegister,
              noKKRegister,
              namaPjmRegister,
              noKtpPjmRegister,
              kodePekerjaanPenjamin: tempPekerjaanPenjamin.data._id,
              ketPekerjaanPenjamin,
              namaRefRegister,
              almRefRegister,
              tlpRefRegister,
              kodeMarketing: tempMarketing.data._id,
              kodeSurveyor: tempSurveyor.data._id,
              kodePekerjaan: tempPekerjaan.data._id,
              ketPekerjaan,
              kodeKelurahan: tempKelurahan.data._id,
              kodeLeasing: tempLeasing.data._id,
              noRangka,
              noMesin,
              nopol,
              tipe,
              namaWarna,
              tahun,
              hargaTunai: hargaTunai.replace(/,/g, ""),
              uangMuka: uangMuka.replace(/,/g, ""),
              sisaPiutang,
              tglUpdate: current_date,
              jamUpdate: current_time,
              userUpdate: user.username,
              kodeCabang: user.cabang._id,
              id: user._id,
              token: user.token,
              kodeCabang: user.cabang._id,
            },
            daftarStokData: {
              jenis: "BARU",
              noRangkaLama: tempNoRangka,
              noRangka,
              noJual,
              tanggalJual: tglJualDate,
              tglUpdate: current_date,
              jamUpdate: current_time,
              userUpdate: user.username,
              kodeCabang: user.cabang._id,
              id: user._id,
              token: user.token,
            },
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          });
        }
        setLoading(false);
        navigate(`/jual/${id}`);
      } catch (error) {
        if (error.response.status == 401) {
          dispatch({ type: "LOGOUT" });
          navigate("/");
        }
        console.log(error);
      }
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box>
      <Typography color="#757575">Penjualan</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Ubah Jual Baru
      </Typography>
      <Divider sx={dividerStyle} />
      <Form noValidate validated={validated} onSubmit={updateJual}>
        <Paper sx={contentContainer} elevation={12}>
          {/* Data Penjualan */}
          <Paper elevation={6} sx={mainContainer}>
            <Typography variant="h5" sx={titleStyle} color="primary">
              DATA PENJUALAN
            </Typography>
            <Box sx={showDataContainer}>
              <Box sx={showDataWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Jual :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noJual} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Kwitansi :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noKwitansi} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tanggal Jual :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={tglJual} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Jenis Jual :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={jenisJual} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[showDataWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        COA :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={kodeCOA}
                          readOnly
                          placeholder="Pilih..."
                          onClick={() => handleClickOpenCOA()}
                          isInvalid={kodeCOA.length === 0 && true}
                        />
                        <Form.Control.Feedback type="invalid">
                          Kode COA harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Paper>

          {/* Data Customer */}
          <Paper elevation={6} sx={mainContainer}>
            <Typography variant="h5" sx={titleStyle} color="primary">
              DATA CUSTOMER
            </Typography>
            <Box sx={showDataContainer}>
              <Box sx={showDataWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Register <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={noRegister}
                          required
                          placeholder="Pilih..."
                          onClick={() => handleClickOpenRegister()}
                        />
                        <Form.Control.Feedback type="invalid">
                          Kode Register harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={namaRegister}
                          onChange={(e) =>
                            setNamaRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Alamat Rumah :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={almRegister}
                          onChange={(e) =>
                            setAlmRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Alamat Kantor :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={almKantor}
                          onChange={(e) =>
                            setAlmKantor(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Telepon Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={tlpRegister}
                          onChange={(e) =>
                            setTlpRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. KTP Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={noKtpRegister}
                          onChange={(e) =>
                            setNoKtpRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. KK Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={noKKRegister}
                          onChange={(e) =>
                            setNoKKRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama Penjamin Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={namaPjmRegister}
                          onChange={(e) =>
                            setNamaPjmRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. KTP Penjaminn Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={noKtpPjmRegister}
                          onChange={(e) =>
                            setNoKtpPjmRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Pekerjaan Penjamin <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={pekerjaanOptions}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              error={
                                error &&
                                kodePekerjaanPenjamin.length === 0 &&
                                true
                              }
                              helperText={
                                error &&
                                kodePekerjaanPenjamin.length === 0 &&
                                "Kode Pekerjaan Penjamin harus diisi!"
                              }
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) =>
                            setKodePekerjaanPenjamin(value)
                          }
                          inputValue={kodePekerjaanPenjamin}
                          onChange={(e, value) =>
                            setKodePekerjaanPenjaminLama(value)
                          }
                          value={kodePekerjaanPenjaminLama}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}></Form.Label>
                      <Col sm="8">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={ketPekerjaanPenjamin}
                          onChange={(e) => {
                            setKetPekerjaanPenjamin(
                              e.target.value.toUpperCase()
                            );
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[showDataWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama Ref. Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={namaRefRegister}
                          onChange={(e) =>
                            setNamaRefRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Alamat Ref. Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={almRefRegister}
                          onChange={(e) =>
                            setAlmRefRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Telepon Ref. Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={tlpRefRegister}
                          onChange={(e) =>
                            setTlpRefRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Marketing <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={marketingOptions}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              error={
                                error && kodeMarketing.length === 0 && true
                              }
                              helperText={
                                error &&
                                kodeMarketing.length === 0 &&
                                "Kode Marketing harus diisi!"
                              }
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => setKodeMarketing(value)}
                          inputValue={kodeMarketing}
                          onChange={(e, value) => setKodeMarketingLama(value)}
                          value={kodeMarketingLama}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Surveyor <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={surveyorOptions}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              error={error && kodeSurveyor.length === 0 && true}
                              helperText={
                                error &&
                                kodeSurveyor.length === 0 &&
                                "Kode Surveyor harus diisi!"
                              }
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => setKodeSurveyor(value)}
                          inputValue={kodeSurveyor}
                          onChange={(e, value) => setKodeSurveyorLama(value)}
                          value={kodeSurveyorLama}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Pekerjaan <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={pekerjaanOptions}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              error={
                                error && kodePekerjaan.length === 0 && true
                              }
                              helperText={
                                error &&
                                kodePekerjaan.length === 0 &&
                                "Kode Pekerjaan harus diisi!"
                              }
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => setKodePekerjaan(value)}
                          inputValue={kodePekerjaan}
                          onChange={(e, value) => setKodePekerjaanLama(value)}
                          value={kodePekerjaanLama}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}></Form.Label>
                      <Col sm="8">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={ketPekerjaan}
                          onChange={(e) => {
                            setKetPekerjaan(e.target.value.toUpperCase());
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Kelurahan <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={kelurahanOptions}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              error={
                                error && kodeKelurahan.length === 0 && true
                              }
                              helperText={
                                error &&
                                kodeKelurahan.length === 0 &&
                                "Kode Kelurahan harus diisi!"
                              }
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => setKodeKelurahan(value)}
                          inputValue={kodeKelurahan}
                          onChange={(e, value) => setKodeKelurahanLama(value)}
                          value={kodeKelurahanLama}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Leasing <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={leasingOptions}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              error={error && kodeLeasing.length === 0 && true}
                              helperText={
                                error &&
                                kodeLeasing.length === 0 &&
                                "Kode Leasing harus diisi!"
                              }
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => setKodeLeasing(value)}
                          inputValue={kodeLeasing}
                          onChange={(e, value) => setKodeLeasingLama(value)}
                          value={kodeLeasingLama}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Paper>

          {/* Data Motor */}
          <Paper elevation={6} sx={mainContainer}>
            <Typography variant="h5" sx={titleStyle} color="primary">
              DATA MOTOR
            </Typography>
            <Box sx={showDataContainer}>
              <Box sx={showDataWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Rangka <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={norangOptions}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              error={error && noRangka.length === 0 && true}
                              helperText={
                                error &&
                                noRangka.length === 0 &&
                                "No. Rangka harus diisi!"
                              }
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => {
                            if (value) {
                              getStoksByNorang(value);
                            } else {
                              setNoMesin("");
                              setTipe("");
                              setNamaWarna("");
                              setTahun("");
                            }
                          }}
                          inputValue={noRangka}
                          onChange={(e, value) => setTempNoRangka(value)}
                          value={tempNoRangka}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Mesin :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noMesin} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col></Col>
                </Row>
              </Box>
              <Box sx={[showDataWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tipe :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={tipe} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama Warna :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={namaWarna} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tahun Perakitan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={tahun} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Paper>

          {/* Data Rincian Harga (Input) */}
          <Paper elevation={6} sx={mainContainer}>
            <Typography variant="h5" sx={titleStyle} color="primary">
              RINCIAN HARGA
            </Typography>
            <Box sx={showDataContainer}>
              <Box sx={showDataWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tanggal Angsuran I :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={tglAng} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Harga Tunai <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        {jenisJual === "KREDIT" && tenor - sisaBulan !== 0 ? (
                          <>
                            <Form.Control
                              value={hargaTunai.toLocaleString("en-US")}
                              disabled
                            />
                          </>
                        ) : (
                          <>
                            <Form.Control
                              value={hargaTunai.toLocaleString("en-US")}
                              onLoad={(e) => {
                                let tempNum;
                                let isNumNan = isNaN(
                                  parseInt(e.target.value.replace(/,/g, ""), 10)
                                );
                                if (isNumNan) {
                                  tempNum = "";
                                } else {
                                  tempNum = parseInt(
                                    e.target.value.replace(/,/g, ""),
                                    10
                                  ).toLocaleString("en-US");
                                }
                                setHargaTunai(tempNum);
                              }}
                              onChange={(e) => {
                                let tempNum;
                                let isNumNan = isNaN(
                                  parseInt(e.target.value.replace(/,/g, ""), 10)
                                );
                                if (isNumNan) {
                                  tempNum = "";
                                } else {
                                  tempNum = parseInt(
                                    e.target.value.replace(/,/g, ""),
                                    10
                                  ).toLocaleString("en-US");
                                }
                                setHargaTunai(tempNum);
                                let tempSisaPiutang =
                                  parseInt(tempNum.replace(/,/g, "")) -
                                  uangMuka;
                                setSisaPiutang(tempSisaPiutang);
                              }}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Harga Tunai harus diisi!
                            </Form.Control.Feedback>
                          </>
                        )}
                        {jenisJual === "KREDIT" && tenor - sisaBulan !== 0 ? (
                          <>
                            <Form.Control
                              value={hargaTunai.toLocaleString("en-US")}
                              disabled
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Uang Muka <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        {jenisJual === "KREDIT" && tenor - sisaBulan !== 0 ? (
                          <>
                            <Form.Control
                              value={uangMuka.toLocaleString("en-US")}
                              disabled
                            />
                          </>
                        ) : (
                          <>
                            <Form.Control
                              value={uangMuka.toLocaleString("en-US")}
                              onChange={(e) => {
                                let tempNum;
                                let isNumNan = isNaN(
                                  parseInt(e.target.value.replace(/,/g, ""), 10)
                                );
                                if (isNumNan) {
                                  tempNum = "";
                                } else {
                                  tempNum = parseInt(
                                    e.target.value.replace(/,/g, ""),
                                    10
                                  ).toLocaleString("en-US");
                                }
                                setUangMuka(tempNum);
                                let tempSisaPiutang =
                                  parseInt(hargaTunai.replace(/,/g, "")) -
                                  parseInt(tempNum.replace(/,/g, ""));
                                setSisaPiutang(tempSisaPiutang);
                              }}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Uang Muka harus diisi!
                            </Form.Control.Feedback>
                          </>
                        )}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Sisa Piutang :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={sisaPiutang.toLocaleString("en-US")}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[showDataWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tanggal Angsuran Akhir :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={tglAngAkhirFormatted} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Angsuran/Bulan <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        {jenisJual === "KREDIT" && tenor - sisaBulan !== 0 ? (
                          <>
                            <Form.Control
                              value={angPerBulan.toLocaleString("en-US")}
                              disabled
                            />
                          </>
                        ) : (
                          <>
                            <Form.Control
                              value={angPerBulan.toLocaleString("en-US")}
                              onChange={(e) => {
                                let tempNum;
                                let isNumNan = isNaN(
                                  parseInt(e.target.value.replace(/,/g, ""), 10)
                                );
                                if (isNumNan) {
                                  tempNum = "";
                                } else {
                                  tempNum = parseInt(
                                    e.target.value.replace(/,/g, ""),
                                    10
                                  ).toLocaleString("en-US");
                                }
                                setAngPerBulan(tempNum);
                                let tempJumlahPiutang =
                                  parseInt(tempNum.replace(/,/g, "")) * tenor;
                                setJumlahPiutang(tempJumlahPiutang);
                              }}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Ang/Bulan harus diisi!
                            </Form.Control.Feedback>
                          </>
                        )}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tenor <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        {jenisJual === "KREDIT" && tenor - sisaBulan !== 0 ? (
                          <>
                            <Form.Control
                              type="number"
                              value={tenorInput}
                              disabled
                            />
                          </>
                        ) : (
                          <>
                            <Form.Control
                              type="number"
                              value={tenorInput}
                              onChange={(e, value) => {
                                countDateDuration(e);
                                setTenorInput(e.target.value);
                                let tempJumlahPiutang =
                                  parseInt(angPerBulan.replace(/,/g, "")) *
                                  e.target.value;
                                setJumlahPiutang(tempJumlahPiutang);
                              }}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Tenor harus diisi!
                            </Form.Control.Feedback>
                          </>
                        )}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Total Piutang :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={jumlahPiutang.toLocaleString("en-US")}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Paper>

          <Box sx={spacingTop}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate(`/jual/${id}`)}
              sx={{ marginRight: 2 }}
            >
              {"< Kembali"}
            </Button>
            <Button variant="contained" startIcon={<SaveIcon />} type="submit">
              Simpan
            </Button>
          </Box>
        </Paper>
      </Form>
      <Divider sx={spacingTop} />
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
      <Dialog
        open={openRegister}
        onClose={handleCloseRegister}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Data Pembeli`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <SearchBar setSearchTerm={setSearchTermRegister} />
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      No. Register
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Tanggal
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Customer</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tempPostsRegister
                    .filter((val) => {
                      if (searchTermRegister === "") {
                        return val;
                      } else if (
                        val.noRegister
                          .toUpperCase()
                          .includes(searchTermRegister.toUpperCase()) ||
                        val.tanggalRegister
                          .toUpperCase()
                          .includes(searchTermRegister.toUpperCase()) ||
                        val.namaRegister
                          .toUpperCase()
                          .includes(searchTermRegister.toUpperCase())
                      ) {
                        return val;
                      }
                    })
                    .map((user, index) => (
                      <TableRow
                        key={user._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          "&:hover": { bgcolor: Colors.grey300 },
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setNoRegister(user.noRegister);
                          setNamaRegister(user.namaRegister);
                          setAlmRegister(user.almRegister);
                          // setAlmKantor(user.almRegister);
                          setTlpRegister(user.tlpRegister);
                          setNoKtpRegister(user.noKtpRegister);
                          setNoKKRegister(user.noKKRegister);
                          setNamaPjmRegister(user.namaPjmRegister);
                          setNoKtpPjmRegister(user.noKtpPjmRegister);
                          setNamaRefRegister(user.namaRefRegister);
                          setAlmRefRegister(user.almRefRegister);
                          setTlpRefRegister(user.tlpRefRegister);
                          handleCloseRegister();
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {user.noRegister}
                        </TableCell>
                        <TableCell>{user.tanggalRegister}</TableCell>
                        <TableCell>{user.namaRegister}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openCOA}
        onClose={handleCloseCOA}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Data COA`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <SearchBar setSearchTerm={setSearchTermCOA} />
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Kode COA
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Nama</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tempPostsCOA
                    .filter((val) => {
                      if (searchTermCOA === "") {
                        return val;
                      } else if (
                        val.kodeCOA
                          .toUpperCase()
                          .includes(searchTermCOA.toUpperCase()) ||
                        val.namaCOA
                          .toUpperCase()
                          .includes(searchTermCOA.toUpperCase())
                      ) {
                        return val;
                      }
                    })
                    .map((user, index) => (
                      <TableRow
                        key={user._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          "&:hover": { bgcolor: Colors.grey300 },
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setKodeCOA(user.kodeCOA);
                          handleCloseCOA();
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {user.kodeCOA}
                        </TableCell>
                        <TableCell>{user.namaCOA}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UbahJualBaru;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const contentContainer = {
  p: {
    sm: 0,
    md: 3,
  },
  pt: {
    sm: 0,
    md: 1,
  },
  mt: {
    sm: 0,
    md: 2,
  },
  backgroundColor: Colors.grey100,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const mainContainer = {
  padding: 3,
  borderRadius: "20px",
  margin: {
    sm: 0,
    md: 4,
  },
  marginTop: {
    xs: 4,
    md: 0,
  },
};

const dialogContainer = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const dialogWrapper = {
  width: "100%",
  marginTop: 2,
};

const titleStyle = {
  textAlign: "center",
  fontWeight: "600",
};

const colorRed = {
  color: "red",
};
