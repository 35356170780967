import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { formatDate, formatTime } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Colors } from "../../../constants/styles";
import {
  Box,
  Typography,
  TextField,
  ButtonGroup,
  Button,
  Divider,
  Autocomplete,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useDownloadExcel } from "react-export-table-to-excel";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import { Col, Row, Form } from "react-bootstrap";

const PenambahanPiutang = () => {
  const tableRef = useRef(null);
  const { user, setting, dispatch } = useContext(AuthContext);
  let date = new Date();
  let [inputDariTgl, setInputDariTgl] = useState(date);
  let [inputSampaiTgl, setInputSampaiTgl] = useState(date);
  const [cabangInput, setCabangInput] = useState("");
  const [totalAModal, setTotalAModal] = useState(0);
  const [totalABunga, setTotalABunga] = useState(0);
  const [penerimaanKas, setPenerimaanKas] = useState([]);

  const [allCabang, setAllCabang] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [persenLoading, setPersenLoading] = useState("0");
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const columns = [
    { title: "No", field: "no" },
    { title: "Cabang", field: "detilCabang" },
    { title: "No. Kontrak", field: "noJual" },
    { title: "Nama", field: "namaRegister" },
    { title: "No. KTP", field: "noKtpRegister" },
    { title: "No. KK", field: "noKKRegister" },
    { title: "Alm. KTP", field: "almKantor" },
    { title: "Alm. Tinggal", field: "almRegister" },
    { title: "Tgl", field: "tanggalJualFormatted" },
    { title: "Nopol", field: "nopol" },
    { title: "Bln", field: "tenor" },
    { title: "P.Ang/Bulan", field: "angPerBulanDoc" },
    { title: "P.Modal", field: "angModalDoc" },
    { title: "P.Bunga", field: "angBungaDoc" },
  ];

  const cabangOptions = allCabang.map((user) => ({
    label: `${user._id} - ${user.namaCabang}`,
  }));

  useEffect(() => {
    if (user.tipeUser === "MGR") {
      getAllCabang();
    } else {
      setCabangInput(user.cabang._id);
    }
  }, []);

  const getAllCabang = async () => {
    setLoading(true);
    const allCabang = await axios.post(`${tempUrl}/cabangs`, {
      id: user._id,
      token: user.token,
    });
    setAllCabang(allCabang.data);
    setLoading(false);
  };

  const downloadPdf = async () => {
    setLoading(true);
    let dariTgl = formatDate(inputDariTgl);
    let sampaiTgl = formatDate(inputSampaiTgl);

    let tempPersenLoading = parseInt(persenLoading);
    const response = await axios.post(`${tempUrl}/penambahanPiutang`, {
      dariTgl: inputDariTgl,
      sampaiTgl: inputSampaiTgl,
      id: user._id,
      token: user.token,
      // kodeCabang: user.cabang._id,
      kodeCabang: cabangInput,
    });
    tempPersenLoading += 50;
    setPersenLoading(tempPersenLoading.toString());

    let tempTotalAPerBulan = 0;
    let tempTotalAModal = 0;
    let tempTotalABunga = 0;
    let tempHeight = 50;
    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    const doc = new jsPDF("l", "mm", [400, 210]);
    doc.setFontSize(12);
    let tempYStart = 10;
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.lokasiPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Laporan Penambahan Piutang`, 160, 30);
    doc.setFontSize(10);
    doc.text(`Dari Tanggal : ${dariTgl} s/d ${sampaiTgl}`, 15, 35);
    doc.text(
      `Cabang : ${cabangInput === "" ? "SEMUA CABANG" : cabangInput}`,
      15,
      40
    );
    doc.setFontSize(12);
    function alignCol(data) {
      var col = data.column.index;
      if (col >= 10) {
        data.cell.styles.halign = "right";
      }
    }
    doc.autoTable({
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 50,
      columns: columns.map((col) => ({ ...col, dataKey: col.field })),
      body: response.data,
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
      didDrawPage: (d) => {
        tempHeight = d.cursor.y;
      },
      didParseCell: function (cell, data) {
        alignCol(cell, data);
      },
      columnStyles: {
        0: { cellWidth: 10 },
        1: { cellWidth: 20 },
        2: { cellWidth: 35 },
        3: { cellWidth: 30 },
        4: { cellWidth: 30 },
        5: { cellWidth: 30 },
        6: { cellWidth: 30 },
        7: { cellWidth: 30 },
        8: { cellWidth: 30 },
        9: { cellWidth: 30 },
        10: { cellWidth: 10 },
        11: { cellWidth: 30 },
        12: { cellWidth: 30 },
        13: { cellWidth: 30 },
        // etc
      },
    });
    response.data.map((val) => {
      tempTotalAPerBulan += val.angPerBulan;
      tempTotalAModal += val.angModal;
      tempTotalABunga += val.angBunga;
    });
    doc.setDrawColor(0, 0, 0);
    doc.setFontSize(10);
    tempHeight += 2;
    if (tempHeight > 151) {
      doc.addPage();
      tempHeight = tempYStart;
    }
    doc.line(15, tempHeight, 390, tempHeight);
    tempHeight += 6;
    doc.text(`TOTAL : `, 15, tempHeight);
    doc.text(
      `${parseInt(tempTotalAPerBulan).toLocaleString("en-US")}`,
      328,
      tempHeight,
      {
        align: "right",
      }
    );
    doc.text(
      `${parseInt(tempTotalAModal).toLocaleString("en-US")}`,
      358,
      tempHeight,
      {
        align: "right",
      }
    );
    doc.text(
      `${parseInt(tempTotalABunga).toLocaleString("en-US")}`,
      388,
      tempHeight,
      {
        align: "right",
      }
    );
    tempHeight += 4;
    doc.line(15, tempHeight, 390, tempHeight);
    tempHeight += 12;
    doc.text(`Mengetahui,`, 80, tempHeight);
    doc.text(`${setting.lokasiSP}, ${current_date}`, 270, tempHeight);
    doc.text(`Dibuat Oleh,`, 275, tempHeight + 6);
    tempHeight += 30;
    doc.line(60, tempHeight, 120, tempHeight);
    doc.line(255, tempHeight, 315, tempHeight);
    tempHeight += 6;
    doc.text(`${user.username}`, 80, tempHeight);
    doc.text(`SPV`, 280, tempHeight);
    setLoading(false);
    window.open(URL.createObjectURL(doc.output("blob")));
    setPersenLoading("0");
  };

  const getPenambahanPiutang = async () => {
    setLoading(true);
    let tempAModal = 0;
    let tempABunga = 0;
    let dariTgl = formatDate(inputDariTgl);
    let sampaiTgl = formatDate(inputSampaiTgl);
    const response = await axios.post(`${tempUrl}/penambahanPiutang`, {
      dariTgl: inputDariTgl,
      sampaiTgl: inputSampaiTgl,
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setPenerimaanKas(response.data);
    response.data.map((val) => {
      tempAModal += val.angModal;
      tempABunga += val.angBunga;
    });
    setTotalAModal(tempAModal);
    setTotalABunga(tempABunga);
    setLoading(false);
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Penambahan Piutang",
    sheet: "PenambahanPiutang",
  });

  if (loading) {
    return <Loader persenLoading={persenLoading} />;
  }

  return (
    <Box>
      <Typography color="#757575">Laporan</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Laporan Penambahan Piutang
      </Typography>
      <Divider sx={dividerStyle} />
      <Box sx={showDataWrapper}>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Cabang :
              </Form.Label>
              {user.tipeUser === "MGR" ? (
                <Col sm="9">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={cabangOptions}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        error={error && cabangInput.length === 0 && true}
                        helperText={
                          error &&
                          cabangInput.length === 0 &&
                          "Cabang harus diisi!"
                        }
                        placeholder="SEMUA CABANG"
                        {...params}
                      />
                    )}
                    onInputChange={(e, value) => {
                      setCabangInput(value.split(" ", 1)[0]);
                    }}
                    value={cabangInput}
                  />
                </Col>
              ) : (
                <Col sm="9">
                  <Form.Control value={cabangInput} disabled />
                </Col>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Dari Tanggal :
              </Form.Label>
              <Col sm="9">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={inputDariTgl}
                  onChange={(e) => setInputDariTgl(e)}
                  customInput={
                    <TextField
                      error={error && inputDariTgl === null && true}
                      helperText={
                        error &&
                        inputDariTgl === null &&
                        "Dari Tanggal harus diisi!"
                      }
                      sx={{ width: "100%" }}
                      size="small"
                    />
                  }
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Sampai Tanggal :
              </Form.Label>
              <Col sm="9">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={inputSampaiTgl}
                  onChange={(e) => setInputSampaiTgl(e)}
                  customInput={
                    <TextField
                      error={error && inputSampaiTgl === null && true}
                      helperText={
                        error &&
                        inputSampaiTgl === null &&
                        "Sampai Tanggal harus diisi!"
                      }
                      sx={{ width: "100%" }}
                      size="small"
                    />
                  }
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
      </Box>
      <Box sx={spacingTop}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button
            variant="contained"
            startIcon={<PrintIcon />}
            onClick={() => downloadPdf()}
          >
            CETAK
          </Button>
          <Button
            startIcon={<DownloadIcon />}
            onClick={() => {
              getPenambahanPiutang();
            }}
          >
            EXCEL
          </Button>
        </ButtonGroup>
      </Box>
      {penerimaanKas.length > 0 && (
        <>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<DownloadIcon />}
            sx={{ marginTop: "10px" }}
            onClick={onDownload}
          >
            EXCEL
          </Button>
        </>
      )}
      <table
        ref={tableRef}
        style={{ visibility: penerimaanKas.length > 0 ? "visible" : "hidden" }}
      >
        <tbody>
          <tr>
            <th>Laporan Penambahan Piutang</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <th>No</th>
            <th>No. Kontrak</th>
            <th>Nama</th>
            <th>Tgl</th>
            <th>Nopol</th>
            <th>Bln</th>
            <th>P.Modal</th>
            <th>P.Bunga</th>
          </tr>
          {penerimaanKas.map((item, i) => (
            <tr>
              <th>{item.no}</th>
              <th>{item.noJual}</th>
              <th>{item.namaRegister}</th>
              <th>{item.tanggalJualFormatted}</th>
              <th>{item.nopol}</th>
              <th>{item.tenor}</th>
              <th>{item.angModal}</th>
              <th>{item.angBunga}</th>
            </tr>
          ))}
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th>{totalAModal}</th>
            <th>{totalABunga}</th>
          </tr>
        </tbody>
      </table>
    </Box>
  );
};

export default PenambahanPiutang;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const spacingTop = {
  mt: 4,
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  // maxWidth: {
  //   md: "40vw"
  // }
};
